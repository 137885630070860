// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';

const ENV_SANDBOX = process.env.IS_SANDBOX ? 'sandbox' : null;
const ENV_BETA = process.env.IS_BETA ? 'beta' : null;

Sentry.init({
  dsn: 'https://1c68e382305e187fab19f6a2687c7894@o4507492234297344.ingest.de.sentry.io/4507492235804752',
  environment: ENV_SANDBOX || ENV_BETA || 'production',
  enabled: process.env.NODE_ENV !== 'development',
  // Performance Monitoring
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    ],
  debug: false,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
